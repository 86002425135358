
.error {
  padding: 10px;
  color: #c53030;
  background-color: #FFF5F5;
  border: 1px solid #c53030;
  border-radius: 8px;
  margin-bottom: 10px;
}

.formatOptionLabelContainer {
  display: flex;
  flex: 1;
  flex-direction: row;
}

.formatOptionLabelImage {
  width: 50px;
  height: 50px;
  border-radius: 10px;
  margin-left: 10px;
}

.formatOptionLabelContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2px;
  margin-left: 10px;
  justify-content: center;
}

.subContentSpan {
  color: #999;
}
